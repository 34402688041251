
@import '~ngx-toastr/toastr';

@font-face {
  font-family: myHelvatikaFont;
  src: url('assets/fonts/helvatika/Helvetica.ttf');
}

@font-face {
  font-family: mySFProFont;
  src: url('assets/fonts/SFPro/FontsFree-Net-SFProDisplay-Semibold.ttf');
}
@font-face {
  font-family: myPoppinsmediumFont;
  src: url('assets/fonts/poppins/Poppins-Medium.ttf');
}
@font-face {
  font-family: myHelvatikabold;
  src: url('assets/fonts/helvatika/Helvetica-Bold.ttf');
}

/*peach payemet gateway starts here*/

html,body,app-root{
  // overflow-x:hidden!important;
  height: 100%;
  display: block;
}
app-invoice-payment-success {
  height: 100%;
  display: block;
}

.wpwl-button:focus, .wpwl-button:active:focus {
  outline:none!important;
  outline-offset:0px!important;
}
.wpwl-button-pay{
  border-color:#fff!important;
}
.wpwl-form-card{
  background-color:rgba(13,89,199,.4)!important;
  padding: 60px 40px !important;
}
.wpwl-button-pay {
  background-color: #fff!important;
  color:#0D59C7!important;
  border-color:0D59C7!important;
}
.wpwl-form-card{
  padding:20px!important;
}

.wpwl-control{
  border:none!important;
}

.wpwl-brand-VISA{
  border-radius:5px!important;
}
.wpwl-form-card{
  //box-shadow: 5px 10px 20px #0000000d!important;
  border:none!important;
}
.wpwl-form{
  margin-bottom:0px!important;
}


.toast-container {
  position: fixed!important;
  top:15px!important;
  right:30px!important;
  z-index:9999999!important;
}
/*peach payment ends here*/

.chart-legend{ display:none!important;}

.ngx-charts .gridline-path {
  stroke: rgba(242,242,242,.9)!important;
}
/*Style for all placehoders*/
/*.chart-legend{
  display:none!important;
}*/
ngx-tooltip-content.ngx-charts-tooltip-content.type-tooltip {
  .tooltip-caret.position-top {
    border-top-color:#fff!important;
    color:#000!important;
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.2);
  }
  color:#000!important;
  background-color:#fff!important;
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.2);
}
.input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
  border-top-right-radius:8px!important;
  border-bottom-right-radius: 8px!important;
}


.ccemails{
  border: 1px solid #ef990f;
  border-radius: 50px!important;
  margin-right: 5px;
  padding: 4px 5px 4px 10px;
  margin-bottom: 9px;
  display: inline-block;
  font-size:14px;
}
.form-control[readonly]{ background-color: #fff!important;}
.cke_top{ display:none!important;}
#discardModal .modal-dialog{ max-width:500px;}
.modal-dialog {
  max-width: 1000px;
}
.iti--container{ z-index:99999999!important; max-width:300px;}
.iti{ display:block!important;display: block; 
width: 100%;
padding:13px 15px;
font-size: 1rem;
font-weight: 400;
line-height: 1.5;
color: #212529;
background-color: #fff;
background-clip: padding-box;
border: 1px solid #ced4da;
-webkit-appearance: none;
-moz-appearance: none;
appearance: none;
border-radius: 0.25rem;
transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;}
.iti__country-list {
  
  position: absolute;
  z-index: 2;
  list-style: none;
  text-align: left;
  padding: 0;
  margin: 0 0 0 -1px;
  box-shadow: 1px 1px 4px rgb(0 0 0 / 20%);
  background-color: white;
  border: 1px solid #CCC;
  white-space: nowrap;
  max-height: 200px;
  /* max-width: 235px; */
  overflow-y: scroll;
  max-width: 500px;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}
.phonewithicon{ padding-left:80px!important; border:none; width:100%;}
.iti__country-list{ overflow-x: hidden;}

html,body{ font-family: myHelvatikaFont!important; background-color:#f3f5f9;}
.form-control.is-invalid{background-image:none!important;}
.hclr tr th{ text-align: left !important; cursor: pointer;
  padding-top: 13px;
  padding-left: 15px; font-family:mySFProFont!important;}
  a:-webkit-any-link:focus-visible {
    outline-offset: 0px!important;
}
:focus-visible {
  outline: none!important;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #000000!important;
    opacity: 1; /* Firefox */
    font-size:14px;
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #000000!important;
    font-size:14px!important;
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
    color: #000000!important;
    font-size:14px!important;
  }
  .modal{ z-index:999999;}
.export>a{border: none; color:#277FFE!important; background: #fff; text-decoration: none; padding: 7px 53px; margin-right: 15px; border-radius:8px; border:1px solid #277FFE;}
 .inputsearch{ background:#fff url(./assets/images/search_black_24dp.png); background-repeat:no-repeat; background-position: right 11px bottom 6px;; padding-right:35px!important; padding-left:11px!important; cursor: pointer;}
 .customdtl tr td a img, i{ margin-right:20px!important; cursor: pointer;}
  .btn-primary{ background-color:#277FFE; border:1px solid #277FFE; padding:7px 25px;}

  /* customer and role common*/
  .export ul{ padding:14px; position:absolute!important; left:0px!important; right:0px!important;    transition: all 0.35s linear;}
  .export ul li{text-align: center; }
  .export ul a{ color:#000; font-size:16px; display:inline-block; text-decoration: none; font-family:myHelvatikaFont;}

  #datatable thead tr{ color:#277FFE; font-size:14px; font-weight: 100;}
.table>:not(:first-child) {
    border-top: none;
}

.customdtl tr td{ padding:15px; color:#000000; letter-spacing: 0px; font-size:16px; font-family: myHelvatikaFont;}

.inputsearch:focus-visible{outline:none;}

.pagination-holder{ display: flex; justify-content: center;}
.pagination{ justify-content: center; display:inline-flex; z-index:0;}
.pagination .page-link{ border:none;}

table tr th,table tr td{white-space: nowrap;}

/*ngx pagination*/

.ngx-pagination{box-shadow: 0px 5px 10px #0000000D;padding: 6px 0px;
  background: #fff;
  border-radius: 5px;}
  .ngx-pagination li { margin-right:0px!important;}

/*.ngx-pagination .pagination-previous a::before{
  content:url('./assets/images/leftimg.png')!important;
}
.ngx-pagination .pagination-next a::after{
  content:url('./assets/images/leftimg.png')!important;
  transform: rotate(180deg);
}
.ngx-pagination .pagination-next.disabled::after{  content:url('./assets/images/leftimg.png')!important;
  transform: rotate(180deg);}
  .ngx-pagination .pagination-previous.disabled::before {
    content:url('./assets/images/leftimg.png')!important;
  }
/*ngx pagination ends here*/

  .ngx-pagination .current {
    border-radius:5px;
    background: #277ffe;
  }

  app-invoice-payment{
     margin: 0 auto !important;
  }
  
@media screen and (max-width:1923px)
{   

  .wpwl-form-card{
    background-color:rgba(13,89,199,.4)!important;
    padding:60px 40px!important;
    min-width:40%!important;
  }

 
  .export.paymentexport >a{
    margin-right:0px;
  }
    .export > a{ padding:15px 0px!important;}
    .customdtl tr td{ padding:19px!important;}
   .editoffcanvas .form-control, .editoffcanvas .form-select{ padding:13px 15px!important;}
    .cancel-save .cancel{
      padding:15px 45px!important;
    }
    .cancel-save .save{ padding:15px 25px!important;}
    .customdtl tr td span.green{ display:block;font-family:myHelvatikaFont; font-size:12px;
      letter-spacing: 0px;
      color: #00D068;}
      
      .customdtl tr td span.red{ display:block;font-family:myHelvatikaFont; font-size:12px;
        letter-spacing: 0px;
        color: red;}

        .export.paymentexport ul{
          right:0px!important;
        }

.trial .modal-dialog{
  min-width:70%!important;
}

}
@media screen and (max-width:1400px)
{ 
  .trial .modal-dialog{
    min-width:80%!important;
  }   
  .wpwl-form-card {
    padding: 20px!important;
    min-width:20%!important;
  }
    .export > a{ padding:7px 0px!important;}
    .customdtl tr td{ padding:16px!important}
    .editoffcanvas .form-control, .editoffcanvas .form-select{ padding:7px 15px!important;}
    .cancel-save .cancel{
      padding:5px 45px!important;
    }
    .cancel-save .save{ padding:5px 20px!important;}
    .right-search .btn-primary{padding:7px!important;}
    .iti{padding: 7px 15px;}
    .modal-dialog {
      max-width: 600px;
    }
    .export.paymentexport ul{
      padding:10px!important;
  }


}


@media screen and (max-width:1157px)
{
  .chart-legend{
    display:none!important;
  }
}

@media screen and (max-width:580px)
{
  .ngx-charts{
    float:none!important;
}
}

.offcanvas-header{
  justify-content: space-between;
  align-items: flex-start;
}